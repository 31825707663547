@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body,html, #root {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}
body{
  overscroll-behavior-y: none;
  overflow-x: hidden !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  /* background: #12071f; */
  background: #0a0113;
}
* {
  box-sizing: border-box;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #3c3152;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #3c3152;
}

::-webkit-scrollbar-thumb
{
	background-color: #160527;
	border: 1px solid #555555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.SkillCardShadow{
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* box-shadow: 0px 10px 15px -3px rgba(149, 157, 165, 0.2); */
  /* box-shadow: 6px 35px 45px 5px rgba(102, 115, 128, 0.1); */
  box-shadow: #1d0b31 0px 48px 100px 0px;
  
}

/* pallet{
  https://coolors.co/palette/000000-14213d-fca311-e5e5e5-ffffff
  yellow: FCA311
  halfwhite: E5E5E5
} */
/* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 20px 25px -5px,
box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */

.Iconinstagram:hover {
  color: #cc2366;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Iconlinkedin-in:hover {
  color: #1da1f2;
  /* background-color: #2867b2; */
  -webkit-background-clip: text;

  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.HeadingText-pinkYellow{
  background: linear-gradient(30deg, #c850c0, #ffcc70);
  -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}