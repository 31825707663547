nav {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin: 0 4rem;  */
  /* position: absolute; 
  width: 90vw; */
  background-color: #0a0113;
  box-shadow: #12061f 0px 19px 23px -18px
}
nav .title {
  /* font-size: 1.5rem; */
  /* margin: 1rem; */
  text-decoration: none;
  color: #fcae30;
  font-weight: bold;
}
nav ul {
  display: flex;
}
nav ul li {
  list-style: none;
}
nav ul li a {
  display: block;
  text-decoration: none;
  color: #ddd;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 0.2rem;
  font-size: 1rem;
}
nav ul li a:not(.active):hover {
  color: #fca311;
}
.active {
  background-color: #35244b;
}
nav .menu {
  display: none;
  position: absolute;
  justify-content: center;
  top: 1.5rem;
  right: 1rem;
  width: 2.25rem;
  height: 1.5rem;
}

nav .menu span {
  height: 0.3rem;
  width: 100%;
  background-color: #dddddd;
  border-radius: 0.4rem;
}

@media (max-width: 480px) {
  nav .menu {
    display: flex;
    
  }
  nav {
    flex-direction: column;
    align-items: start;
    /* position: absolute;
    margin: 0 2rem;  */
  }
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav .open {
    display: flex;
  }
  nav ul li {
    width: 100%;
    text-align: center;
  }
  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}
