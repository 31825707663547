
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


h1{
    background:  white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.05em;
  } 
  .Text-pinkYellow{
    background: linear-gradient(30deg, #c850c0, #ffcc70);
    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-weight: bold; */
  }
  .HeroContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
    
  }
  .flex-col h5{
    color:  white;
    font-size: 3rem;
    font-weight: 600;
    /* letter-spacing: 0.05em; */
    margin: 0 0 0 0.25rem;
  }

  .flex-col p{
    color:  white;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    margin: 0.5rem 0 0.5rem 0.25rem;
    font-weight: 400;
    
  }